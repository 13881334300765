import { useState, useEffect } from "react";
import { Floating } from "./components/Floating";
import { Logo } from "./components/Logo";
import { Slider } from "./components/Slider";
import { SliderProducts } from "./components/SliderProducts";
import { SliderServices } from "./components/SliderServices";
import { Form2 } from "./components/Form2";
import { FormContent } from "./components/FormContent"; // Importamos FormContent
import { Security } from "./components/Security";
import { Services } from "./components/Services";
import { PopUpForm } from "./components/PopUpForm";

import "./assets/css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [popUpForm, setPopUpForm] = useState(false);
  const [text, setText] = useState("alarma para casa");
  const brightness = popUpForm ? "fullPage brightness" : "fullPage";
  const url = window.location.pathname;
  const [data, setData] = useState(null);

  // Estado para guardar los datos del formulario cuando se complete
  const [formData, setFormData] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);
  

  const fetchData = async () => {
    try {
      const response = await fetch("/landing-config.json");
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const jsonData = await response.json();
  
      const isPromo = window.location.pathname.includes("/promo");
  
      const selectedData = isPromo
        ? { phoneNumber: jsonData.phoneNumberClaro, phoneNumberIcon: jsonData.phoneNumberClaroIcon }
        : { phoneNumber: jsonData.phoneNumber, phoneNumberIcon: jsonData.phoneNumberIcon };
  
      setData(selectedData);
      console.log("📌 Datos cargados correctamente:", selectedData);
    } catch (error) {
      console.error("❌ Error al obtener datos:", error);
    }
  };
  
  

  useEffect(() => {
    fetchData();
  }, []);

  const handlerPopUpForm = (text) => {
    setText(text);
    openPopUpFormState();
  };

  const openPopUpFormState = () => {
    setPopUpForm(!popUpForm);
  };

  const closePopUpForm = () => {
    if (popUpForm) {
      setPopUpForm(false);
    }
  };

  // Función que recibe los datos del formulario al completarse
  const handleFormComplete = (formValues) => {
    setFormData(formValues);
    setFormCompleted(true);
  };

  return (
    <div>
      {popUpForm && (
        <div className="background-brightness">
          <PopUpForm text={text} handlerPopUpFormState={openPopUpFormState} />
        </div>
      )}

      {data !== null && (
        <div className={brightness} onClick={closePopUpForm}>
          {/* Barra superior con Logo y Floating */}
          <div className="top-bar">
            <Logo />
            <Floating data={data} />
          </div>

          {/* Slider dinámico según la URL */}
          <div className="slider-position">
            {url === "/servicios" ? (
              <SliderServices data={data} handlerPopUpForm={handlerPopUpForm} />
            ) : url === "/productos" ? (
              <SliderProducts data={data} handlerPopUpForm={handlerPopUpForm} />
            ) : (
              <Slider data={data} handlerPopUpForm={handlerPopUpForm} />
            )}
          </div>

          {/* Sección del formulario */}
          <div className="form-position">
            {!formCompleted ? (
              <Form2 onComplete={handleFormComplete} />
            ) : (
              <FormContent formData={formData} />
            )}
          </div>

          {/* Otras secciones */}
          <div className="security-position">
            <Security data={data} />
          </div>
          <div className="services-position">
            <Services data={data} />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
