import React, { useState } from "react";
import { FormMessage } from "./FormMessage";
import axios from "axios";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from "../hooks/useForm";
import "../assets/css/form.css";

export const PopUpForm = () => {
  const initialForm = {
    fullName: "",
    email: "",
  };

  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const [correctValidation, setCorrectValidation] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [popup, setPopup] = useState("popup popuptext");
  const [valueCheck, setValueCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  console.log(recaptchaSiteKey, "recaptchaSITEKEY")

  // Obtener parámetros UTM
  const utms = window.location.search.substring(1).split("&");
  const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
  const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
  const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
  const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
  const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";
  
  const validar_email = (email) => {
    if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){
      return true
    } else {
      return false
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!window.grecaptcha) {
      console.error("reCAPTCHA no se ha cargado");
      setCorrectValidation(6);
      setLoading(false);
      return;
    }

    // Verificar todos los campos antes de proceder con reCAPTCHA
    if (!formValues.fullName) {
      setCorrectValidation(4); // Asumiendo que 4 es para nombre inválido
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
      return;
    }

    // Validar email
    if (!validar_email(formValues.email)) {
      setCorrectValidation(3);
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
      return;
    }

    // Validar teléfono
    if (!isValidPhoneNumber(phoneNumber)) {
      setCorrectValidation(2);
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
      return;
    }

    // Verificar términos y condiciones
    if (!valueCheck) {
      setCorrectValidation(5); // Usar un código específico para términos no aceptados
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
      return;
    }

    // Obtener el token de reCAPTCHA antes de enviar los datos
    try {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(recaptchaSiteKey, { action: "submit" });
          console.log("token", token)
          // Crear objeto de lead
          let lead = {
            name: formValues.fullName,
            phone: phoneNumber.slice(1),
            whatsapp: phoneNumber.slice(1),
            email: formValues.email,
            utmSource: utm_source,
            leadProduct: "ADT_AR",
            leadSource: "Google",
            utmMedium: utm_medium,
            utmCampaign: utm_campaign,
            utmTerm: utm_term,
            utmContent: utm_content,
            state: "",
            recaptchaToken: token,
          };

          try {
            const response = await axios.post("processor.php", lead, {
              headers: { "Content-Type": "application/json" },
            });
            
            if (response.data.status === "success" || (typeof response.data === "string" && response.data.includes("success"))) {
              setCorrectValidation(1);
              reset();
              setValueCheck(false);
              setPhoneNumber("");
            } else {
              console.warn("Response error:", response.data);
              setCorrectValidation(5);
            }
          } catch (error) {
            console.error("Axios error:", error);
            setCorrectValidation(5);
          } finally {
            setLoading(false);
            setTimeout(() => setCorrectValidation(0), 4000);
          }
        } catch (recaptchaError) {
          console.error("reCAPTCHA error:", recaptchaError);
          setCorrectValidation(6);
          setLoading(false);
          setTimeout(() => setCorrectValidation(0), 4000);
        }
      });
    } catch (error) {
      console.error("General error:", error);
      setCorrectValidation(5);
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
    }
  };

  const handlerPopup = () => {
    setPopup((prev) => (prev === "popup popuptext" ? "popup popuptext-show" : "popup popuptext"));
  };

  return (
    <div className="form-title">
      <div className="form">
        <div className="message">
          <FormMessage correctValidation={correctValidation} />
        </div>
        <h2>DEJÁ TUS DATOS Y TE ASESORAMOS</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="fullName">Nombre y apellido *</label>
          <input type="text" name="fullName" value={formValues.fullName} onChange={handleInputChange} required />

          <label htmlFor="phoneNumber">Teléfono de contacto *</label>
          <PhoneInput defaultCountry="AR" value={phoneNumber} onChange={setPhoneNumber} required />

          <label htmlFor="email">E-mail *</label>
          <input type="email" name="email" value={formValues.email} onChange={handleInputChange} required />

          <div className="checkForm">
            <div className="conditions" onClick={handlerPopup}>
              <label htmlFor="checkbox" className="checkLabel">Acepto términos y condiciones</label>
            </div>
            <div className="checkbox-container">
              <input 
                type="checkbox" 
                id="check" 
                checked={valueCheck} 
                onChange={() => setValueCheck(!valueCheck)} 
                required 
              />
              <span id="popup" className={popup} onClick={handlerPopup}>
                TÉRMINOS Y CONDICIONES DEL SERVICIO DE MONITOREO...
              </span>
            </div>
          </div>

          {loading ? (
            <button type="submit" disabled>
              <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span> Cargando...
            </button>
          ) : (
            <button type="submit">Solicitar asesoramiento</button>
          )}
        </form>
      </div>
    </div>
  );
};