import React, { useState } from "react";
import { FormMessage } from "./FormMessage";
import axios from "axios";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from "../hooks/useForm";
import "../assets/css/form.css";

export const FormContent = ({ formData }) => {
  const initialForm = {
    fullName: "",
    email: "",
  };

  // ✅ Verificar si `formData` existe antes de formatear
  const formattedData = formData
    ? `
  Tipo de propiedad: ${formData.type_propriety}
  Tiene servicio: ${formData.has_service}
  Niveles de la casa: ${formData.houses_levels}
  Provincia: ${formData.provincia}
  Ha sufrido robo: ${formData.theft}`
    : "❌ No hay datos disponibles.";

  //console.log("📌 formattedData:", formattedData);

  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const [correctValidation, setCorrectValidation] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [popup, setPopup] = useState("popup popuptext");
  const [valueCheck, setValueCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const recaptchaSiteKey = "6LfjtPQqAAAAAELh3xhVWjyzLVstzuoIcuwBNL7F";
  //console.log("📌 reCAPTCHA Key:", recaptchaSiteKey);

  // Obtener parámetros UTM
  const utms = window.location.search.substring(1).split("&");
  const getUTM = (index) => (utms[index] ? utms[index].split("=").pop() : "Organic");
  
  const utm_source = getUTM(0);
  const utm_medium = getUTM(1);
  const utm_campaign = getUTM(2);
  const utm_term = getUTM(3);
  const utm_content = getUTM(4);

  const validar_email = (email) => {
    return /^[-\w.%+]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!window.grecaptcha) {
      console.error("❌ reCAPTCHA no se ha cargado");
      setCorrectValidation(6);
      setLoading(false);
      return;
    }

    if (!formValues.fullName) {
      setCorrectValidation(4);
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
      return;
    }

    if (!validar_email(formValues.email)) {
      setCorrectValidation(3);
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
      return;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      setCorrectValidation(2);
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
      return;
    }

    if (!valueCheck) {
      setCorrectValidation(5);
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
      return;
    }

    try {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(recaptchaSiteKey, { action: "submit" });
          //console.log("✅ reCAPTCHA token:", token);

          let lead = {
            name: formValues.fullName,
            phone: phoneNumber.slice(1),
            whatsapp: phoneNumber.slice(1),
            email: formValues.email,
            utmSource: utm_source,
            leadProduct: "ADT_AR",
            leadSource: "Google",
            utmMedium: utm_medium,
            utmCampaign: utm_campaign,
            utmTerm: utm_term,
            utmContent: utm_content,
            state: "",
            recaptchaToken: token,
            description: formattedData,
          };

          //console.log("📌 Enviando Lead:", lead);

          const response = await axios.post("processor.php", lead, {
            headers: { "Content-Type": "application/json" },
          });

          if (response.data.status === "success" || (typeof response.data === "string" && response.data.includes("success"))) {
            //console.log("✅ Lead enviado correctamente.");
            setCorrectValidation(1);
            reset();
            setValueCheck(false);
            setPhoneNumber("");
          } else {
            console.warn("⚠️ Error en respuesta:", response.data);
            setCorrectValidation(5);
          }
        } catch (error) {
          console.error("❌ Error en envío de Lead:", error);
          setCorrectValidation(5);
        } finally {
          setLoading(false);
          setTimeout(() => setCorrectValidation(0), 4000);
        }
      });
    } catch (error) {
      console.error("❌ Error general:", error);
      setCorrectValidation(5);
      setLoading(false);
      setTimeout(() => setCorrectValidation(0), 4000);
    }
  };

  return (
    <div className="form-title">
      <div className="form">
        <div className="message">
          <FormMessage correctValidation={correctValidation} />
        </div>
        <h2>DEJÁ TUS DATOS Y TE ASESORAMOS</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="fullName">Nombre y apellido *</label>
          <input type="text" name="fullName" value={formValues.fullName} onChange={handleInputChange} required />

          <label htmlFor="phoneNumber">Teléfono de contacto *</label>
          <PhoneInput defaultCountry="AR" value={phoneNumber} onChange={setPhoneNumber} required />

          <label htmlFor="email">E-mail *</label>
          <input type="email" name="email" value={formValues.email} onChange={handleInputChange} required />

          <div className="checkForm">
            <div className="conditions">
              <label htmlFor="checkbox" className="checkLabel">Acepto términos y condiciones</label>
            </div>
            <div className="checkbox-container">
              <input type="checkbox" id="check" checked={valueCheck} onChange={() => setValueCheck(!valueCheck)} required />
            </div>
          </div>

          {loading ? (
            <button type="submit" disabled>
              <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span> Cargando...
            </button>
          ) : (
            <button type="submit">Solicitar asesoramiento</button>
          )}
        </form>
      </div>
    </div>
  );
};
