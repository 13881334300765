import React, { useState } from "react";
import { useForm } from "../hooks/useForm";
import { FormContent } from "./FormContent"; // Importamos FormContent
import "../assets/css/form2.css";
import "../assets/css/radio.css";
import "../assets/css/dropdown.css";
import "../assets/css/form.css"; // Importamos los estilos de FormContent

export const Form2 = ({ onComplete }) => {
  const totalSteps = 5; // Último paso antes de FormContent

  const initialForm = {
    type_propriety: "",
    has_service: "",
    theft: "",
    houses_levels: "",
    provincia: "",
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [emptyError, setEmptyError] = useState({});
  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSelectNextClick = async (event) => {
    handleInputChange(event);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleDropdownNextClick = () => {
    if (formValues.provincia) {
      if (currentStep === 4) {
        //console.log("✅ Formulario completado, ejecutando handleFormSubmitSuccess");
        handleFormSubmitSuccess(); 
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else {
      setEmptyError({ ...emptyError, provincia: true });
    }
  };
  
  
  // Se ejecuta cuando FormContent envía los datos correctamente
  const handleFormSubmitSuccess = () => {
    //console.log("✅ Formulario completado, enviando datos a App:", formValues);
    setShowSuccessMessage(true);
    reset();
    if (window.fbq) {
      window.fbq("track", "CompleteRegistration");
    }
    if (onComplete) {
      onComplete({ ...formValues }); // Pasamos una copia del objeto
    }
  };
  

  return (
    <div className="row">
      <div className="col-md-8 col-md-offset-3">
        <div id="msform">
          {!showSuccessMessage ? (
            <>
              <h1>ADT garantiza la seguridad de tu hogar.</h1>
              <ul id="progressbar">
                {[...Array(totalSteps + 1)].map((_, i) => (
                  <li key={i} className={currentStep >= i ? "active" : ""}></li>
                ))}
              </ul>
            </>
          ) : null}

          {/* PASOS DEL FORMULARIO */}

          {/* 1. Tipo de propiedad */}
          <fieldset style={{ display: currentStep === 0 ? "block" : "none" }}>
            <h3 className="fs-subtitle">¿Qué clase de propiedad es?</h3>
            <div className="selection-container-type-property">
              <label className="selection_radio">
                <h3 className="radio-h3">Casa</h3>
                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="type_propriety"
                  value="hogar"
                />
              </label>
              <label className="selection_radio">
                <h3 className="radio-h3">Negocio</h3>
                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="type_propriety"
                  value="negocio"
                />
              </label>
            </div>
          </fieldset>

          {/* 2. Servicio actual */}
          <fieldset style={{ display: currentStep === 1 ? "block" : "none" }}>
            <h3 className="fs-subtitle">¿Tenés algún sistema de seguridad?</h3>
            <div className="selection-container-type-property">
              <label className="selection_radio">
                <h3 className="radio-h3">No</h3>
                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="has_service"
                  value="no_tiene_servicio"
                />
              </label>
              <label className="selection_radio">
                <h3 className="radio-h3">Sí</h3>
                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="has_service"
                  value="si_tiene_servicio"
                />
              </label>
            </div>
          </fieldset>

          {/* 3. Robo en la vivienda */}
          <fieldset style={{ display: currentStep === 2 ? "block" : "none" }}>
            <h3 className="fs-subtitle">¿Fuiste víctima de un robo?</h3>
            <div className="selection-container-type-property">
              <label className="selection_radio">
                <h3 className="radio-h3">Sí</h3>
                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="theft"
                  value="si_robado"
                />
              </label>
              <label className="selection_radio">
                <h3 className="radio-h3">No</h3>
                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="theft"
                  value="no_robado"
                />
              </label>
            </div>
          </fieldset>

          {/* 4. Cantidad de pisos */}
          <fieldset style={{ display: currentStep === 3 ? "block" : "none" }}>
            <h3 className="fs-subtitle">¿Cuántos pisos tiene tu inmueble?</h3>
            <div className="selection-container">
              <label className="selection_radio">
                <h3 className="radio-h3">1 piso</h3>
                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="houses_levels"
                  value="1_piso"
                />
              </label>
              <label className="selection_radio">
                <h3 className="radio-h3">2 pisos</h3>
                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="houses_levels"
                  value="2_pisos"
                />
              </label>
              <label className="selection_radio">
                  
                <h3 className="radio-h3">Más de 3 pisos</h3>

                <input
                  onClick={handleSelectNextClick}
                  className="radio_button"
                  type="radio"
                  name="houses_levels"
                  value="3_pisos"
                />
              </label>
            </div>
          </fieldset>

          {/* 5. Seleccionar provincia */}
          <fieldset style={{ display: currentStep === 4 ? "block" : "none" }}>
            <h3 className="fs-subtitle">Seleccione su Provincia:</h3>
            <select
              name="provincia"
              className={emptyError.provincia ? "error-text" : ""}
              value={formValues.provincia}
              onChange={handleInputChange}
              onBlur={() =>
                setEmptyError({ ...emptyError, provincia: !formValues.provincia })
              }
            >
              <option value="" disabled hidden>Seleccionar provincia</option>
              <option value="caba">Ciudad Autónoma de Buenos Aires</option>
              <option value="buenos_aires">Buenos Aires</option>
              <option value="catamarca">Catamarca</option>
              <option value="chaco">Chaco</option>
              <option value="chubut">Chubut</option>
              <option value="cordoba">Córdoba</option>
              <option value="corrientes">Corrientes</option>
              <option value="entre_rios">Entre Ríos</option>
              <option value="formosa">Formosa</option>
              <option value="jujuy">Jujuy</option>
              <option value="la_pampa">La Pampa</option>
              <option value="la_rioja">La Rioja</option>
              <option value="mendoza">Mendoza</option>
              <option value="misiones">Misiones</option>
              <option value="neuquen">Neuquén</option>
              <option value="rio_negro">Río Negro</option>
              <option value="salta">Salta</option>
              <option value="san_juan">San Juan</option>
              <option value="san_luis">San Luis</option>
              <option value="santa_cruz">Santa Cruz</option>
              <option value="santa_fe">Santa Fe</option>
              <option value="santiago_del_estero">Santiago del Estero</option>
              <option value="tierra_del_fuego">Tierra del Fuego, Antártida e Islas del Atlántico Sur</option>
              <option value="tucuman">Tucumán</option>

                          </select>
            <button className="action-button" onClick={handleDropdownNextClick}>
              <h3 className="radio-h3">Continuar</h3>
            </button>
          </fieldset>


          {showSuccessMessage && (
            <div>
              <h4>¡GRACIAS!</h4>
              <h5>Recibimos tu solicitud</h5>
              <h6>Un asesor te contactará pronto.</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form2;
